import React, { useState, useEffect, memo } from 'react'
import { Helmet } from 'react-helmet'
import { SimpleGrid, Container, Box, useDisclosure } from '@chakra-ui/react'

/** Selectors */
import { CashCollectionSelector } from './selectors/CashCollectionSelector'
import { InvitationSelector } from './selectors/InvitationSelector'
import { FinalizeSelector } from './selectors/FinalizeSelector'
import { PreviewSelector } from './selectors/PreviewSelector'
/** Components */
import FooterBase from '../../molecules/FooterBase'
import Addons from './Addons'
import MediaGallery from './MediaGallery'
/** Hooks */
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useMedia } from '../../../hooks/useMedia'
import useFunders from '../../../hooks/useFunders'
import useCoverPhoto from '../../../hooks/useCoverPhoto'
import { useEventRecipients } from '../../../hooks/useRecipients'
import useOptimizedResize from 'use-optimized-resize'
import { history } from '../../../../history'
import { useLocation } from 'react-router-dom'
import { logAddToCartEvent } from '@vidday/utils'
import useAutoRestoreGlacierMedia from '../../../hooks/useAutoRestoreGlacierMedia'
import { CustomSelector } from './selectors/CustomSelector'
import { MediaPendingJobModal } from '../../organisms/ModalPendingJobInfo'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import loadable from '@loadable/component'
const AssistantBanner = loadable(() => import(/* webpackPrefetch: true */ '../../molecules/AssistantBanner'), {
	ssr: false,
})
const EventHeader = loadable(() => import(/* webpackPrefetch: true */ '../../organisms/event-header/index.tsx'), {
	ssr: false,
})
export const useEventCashCollection = (event) => {
	// const previousEventRef = useRef(event)
	// const { event } = useEventContext()
	const funders = useFunders()

	// const [doesCollect, setDoesCollect] = useState(false)
	const [amount, setAmount] = useState(0)

	useEffect(() => {
		// if (previousEventRef.current !== event) {
		let currentCash = 0

		// const doesCollectVal = funders.length > 0 ? true : event.allowCashCollection ? true : false
		funders.forEach((el) => (currentCash += el.amount))

		// setDoesCollect(doesCollectVal)
		setAmount(currentCash)
		// }
	}, [funders, event])

	return {
		// doesCollect,
		amount,
		funders,
	}
}

function areEqual(prevProps, nextProps) {
	/*
	return true if passing nextProps to render would return
	the same result as passing prevProps to render,
	otherwise return false
	*/

	/**
	 * If we have a previous and next event,
	 * If id is the same for both event,
	 * If the modified date is identical (Warning: because the date will differ on a sort, this will cause re-render)
	 */
	if (
		JSON.stringify(prevProps.event) === JSON.stringify(nextProps.event)
		// (prevProps.event && nextProps.event) &&
		// (prevProps.event.id === nextProps.event.id) &&
		// (prevProps.event.updatedAt === nextProps.event.updatedAt)
	) {
		return true
	} else return false
}

const Event = memo(({ event }) => {
	useScrollTopOnMount()
	useAutoRestoreGlacierMedia(event)
	const recipients = useEventRecipients()
	const location = useLocation()
	const handlers = useEventHandlersV2(event.uuid)
	const media = useMedia()
	const coverPhoto = useCoverPhoto()
	const { amount } = useEventCashCollection(event)
	const [width] = useOptimizedResize()
	// const size = useWindowSize()
	const [metaPageTitle, setMetaPageTitle] = useState(`VidDay for ${recipients.formatted}`)
	const [showOnboarding, setShowOnboarding] = useState(false)
	const [mediaStatus, setMediaStatus] = useState('')

	const { onClose, onOpen, isOpen } = useDisclosure()

	const handleRedirectInvitation = () => {
		// add inviteflag to localStorage
		try {
			if (typeof window != 'undefined') localStorage?.setItem('inviteflag', true)
		} catch (err) {
			console.log("Can't access local storage : inviteflag ", err)
		}
		history.push(handlers.invitation)
	}
	const handleRedirectCollect = () => history.push(handlers.fund_contribute)
	const handleRedirectCustom = () => history.push(handlers.customize)
	const handleRedirectPreview = () => history.push(handlers.preview)
	const handleRedirectFinalize = () => history.push(handlers.finalize)

	// commenting this extra media sorting order check function as of now as it's not needed with this branch code change but feel free to uncomment if required in future.
	/*const updateMediaSortingorder = () => {
		let newSortMedia = sortMedia(media, event.mediaSortingOrder)
		let newSortingOrder = newSortMedia?.map((m) => m.id)

		if (JSON.stringify(newSortingOrder) !== JSON.stringify(event.mediaSortingOrder)) {
			dispatch(
				updateEvent(
					{
						id: event.id,
						mediaSortingOrder: newSortingOrder,
					},
					true
				)
			)
		}
	}*/

	const handleCustomization = () => {
		handleRedirectCustom()
	}

	const calculatePendingJobStatus = (media) => {
		let isPending = false

		media &&
			media.forEach((m) => {
				let hasUpscaledImage = m.jobId?.split(':')[0] == 'nero' && m.upscaledKey
				if (m.jobState && (m.jobState === 'processing' || m.jobState === 'error' || hasUpscaledImage)) {
					isPending = true
				}

				// used to determine what info the MediaPendingJobModal should display
				if (m.jobState && m.jobState === 'processing') {
					setMediaStatus('processing')
				} else if (m.jobState && m.jobState === 'error') {
					setMediaStatus('error')
				} else if (m.jobState && hasUpscaledImage) {
					setMediaStatus('upscaling')
				}
			})
		return isPending
	}

	const handlePreview = () => {
		/** comment extra media sort check */
		// updateMediaSortingorder()
		calculatePendingJobStatus(media) ? onOpen() : handleRedirectPreview()
	}

	const handleFinalize = () => {
		/** comment extra media sort check */
		// updateMediaSortingorder()
		calculatePendingJobStatus(media) ? onOpen() : handleRedirectFinalize()
	}

	useEffect(() => {
		setShowOnboarding(media.length < 4 && !coverPhoto ? true : false)
	}, [media])

	useEffect(() => {
		if (location.search.includes('addtocart=true') && event.id != -1) {
			logAddToCartEvent({ event: event, recipients: recipients.formatted })
			history.push(location.pathname)
		}
	}, [event.id])

	useEffect(() => {
		setMetaPageTitle(`VidDay for ${recipients.formatted}`)
	}, [recipients])

	return (
		<>
			<Helmet>
				<title>{metaPageTitle}</title>
			</Helmet>

			{/* {width <= 468 && coverPhoto && <CoverPhotoMobile />} */}

			<Container maxW="container.xl" py="1rem">
				<EventHeader />

				{/* <CoverHeading event={event} /> */}

				<AssistantBanner />

				<SimpleGrid columns={[1, 1, 2]} spacing="1rem" mb="1rem" alignItems="end">
					<InvitationSelector onClick={handleRedirectInvitation} />
					<CashCollectionSelector amount={amount} onClick={handleRedirectCollect} />
				</SimpleGrid>

				<Box mb="1rem">
					<MediaGallery event={event} />
				</Box>

				<SimpleGrid columns={[1, 1, 1, 3]} spacing="1rem" mb="1rem">
					<CustomSelector onClick={handleCustomization} />
					<PreviewSelector onClick={handlePreview} />
					<FinalizeSelector event={event} onClick={handleFinalize} />
				</SimpleGrid>
				{media.length > 1 && <Addons event={event} />}
			</Container>

			<MediaPendingJobModal onClose={onClose} isOpen={isOpen} mediaStatus={mediaStatus} />

			<FooterBase />
		</>
	)
}, areEqual)

export default Event
