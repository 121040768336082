/* **************************************
 *  LIBRARIES
 * ************************************ */
import React, { useState, useRef, useEffect } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { Box, VStack, GridItem } from '@chakra-ui/react'
import {
	First,
	Last,
	CollageBackground,
	CollageForeground,
	Muted,
	BottomSection,
	Thumbnail,
	BackgroundMusic,
	Attention,
} from './atoms'

import useSize from '../../../../../hooks/useSize'
import { useDispatch } from 'react-redux'
import { loadEvent } from '../../../../../../api/app/events'
import { useParams } from 'react-router-dom'

const BaseElement = ({
	// variant,
	data,
	hasCollageBackground,
	gridSize,
	hashSelection,
	setMediaToEdit,
	onItemSelect,
	selected,
	selectedItemsLength,
	//v2
	isSelected,
	isFirst,
	isLast,
	// benchmarking
	styles,
}) => {
	const dispatch = useDispatch()
	const params = useParams()
	const boxRef = useRef(null)
	const [minH, setMinH] = useState(false)
	const [touchtime, setTouchtime] = useState(0)

	const size = useSize(boxRef)

	useEffect(() => {
		/** Force matching the height to the width of the element */
		if (size && size.width != size.height) {
			setMinH(size.width)
		}
	}, [size])

	const [reloadOption, setReloadOption] = useState(false)
	useEffect(() => {
		var processingTimeout
		if (!data.signedLocationUrl || data.jobState == 'processing' || data.jobState == 'error') {
			processingTimeout = setTimeout(() => {
				setReloadOption(true)
			}, 150000)
		}
		return () => {
			clearTimeout(processingTimeout)
		}
	}, [data])

	const handleSelect = (e) => {
		onItemSelect(data.id)
	}

	const handleClick = (e) => {
		e.preventDefault()
		if (reloadOption) {
			if (params.uuid) dispatch(loadEvent(params.uuid))
		} else {
			//DoubleCLick function
			if (touchtime == 0) {
				setTouchtime(new Date().getTime())
				// handleSelect(e)
				handleSelect(e)
			} else {
				if (new Date().getTime() - touchtime < 800) {
					// Double click actived hide tooltip
					try {
						if (typeof window != 'undefined') localStorage?.setItem('viewedmedia', true)
					} catch (err) {
						console.log("Can't access local storage : viewedmedia ", err)
					}
					setMediaToEdit(data)
					setTouchtime(0)
				} else {
					handleSelect(e)
					// setTouchtime(0)
					setTouchtime(new Date().getTime())
				}
			}
		}
	}

	const type = data.mimeType ? data.mimeType.split('/')[0] : null

	const isVideo = type == 'video' ? true : false

	const isHashSelection = hashSelection?.replace('#', '') == data.id

	/**
	 * Tags Conditions
	 */
	const displayCollageBackground =
		data.backgroundEligible && !data.foregroundEligible && data.signedThumbnailUrl && hasCollageBackground

	const displayCollageForeground =
		data.foregroundEligible && !data.backgroundEligible && data.signedThumbnailUrl && hasCollageBackground

	const displayBackgroundMusic = data.muteBackgroundMusic
	const displayMute = data.muteAudio && isVideo
	const ignore = ['Giphy', 'Unsplash']
	const hasLowResolution = data.hasLowResolution && !ignore.includes(data.medium)
	// const displayRotation = data.rotation != '0' && data.rotation != 0

	const boxShadow = isSelected
		? '0 0 0 5px var(--vidday-colors-pink-500)'
		: isHashSelection && selectedItemsLength == 0
		? '0 0 4px 3px var(--vidday-colors-pink-500)'
		: 'none'

	return (
		<GridItem pos="relative" ref={boxRef} minH={minH ? minH : 'auto'} id={data.id}>
			{isSelected && selectedItemsLength > 1 ? <Box sx={{ ...styles.badge }}>{selectedItemsLength}</Box> : null}
			{isSelected && selectedItemsLength > 1 ? <Box sx={{ ...styles.layers }} /> : null}
			<Box
				userSelect="none"
				pos="relative"
				sx={{ ...styles.element }}
				boxShadow={boxShadow}
				onClick={handleClick}>
				<Thumbnail item={data} processingText={reloadOption ? 'Click to reload' : null} gridSize={gridSize} />

				<BottomSection item={data} hasCollageBackground={hasCollageBackground} gridSize={gridSize} />

				<Box position="absolute" top="0" left="0" right="0" h="75px">
					<VStack p="0.5rem" align="start" spacing="0.25rem">
						{isFirst && <First />}
						{isLast && <Last />}
						{displayCollageBackground && <CollageBackground />}
						{displayCollageForeground && <CollageForeground />}
						{displayBackgroundMusic && <BackgroundMusic />}
						{displayMute && <Muted />}
						{hasLowResolution && <Attention />}
					</VStack>
				</Box>
			</Box>
		</GridItem>
	)
}

export default SortableElement((props) => {
	return <BaseElement {...props} />
})
